import revive_payload_client_4sVQNw7RlN from "/vercel/path0/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_KZ0f4ARq4r from "/vercel/path0/src/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import plugin_jBToDIZpFa from "/vercel/path0/src/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import sentry_3AyO8nEfhE from "/vercel/path0/src/plugins/sentry.ts";
import plugin_auto_pageviews_client_cBh4f50wXK from "/vercel/path0/src/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.mjs";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_KZ0f4ARq4r,
  plugin_jBToDIZpFa,
  chunk_reload_client_UciE0i6zes,
  sentry_3AyO8nEfhE,
  plugin_auto_pageviews_client_cBh4f50wXK
]