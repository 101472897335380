<script>

</script>

<template>
	<Head>
		<title>Neuronoir</title>
	</Head>
  <div>
      <NuxtLayout></NuxtLayout>
  </div>
</template>
