import { default as aboutTska6UMHuWMeta } from "/vercel/path0/src/pages/about.vue?macro=true";
import { default as _91slug_93zny7bE9xqjMeta } from "/vercel/path0/src/pages/artists/[slug].vue?macro=true";
import { default as indexqcGbOSNNUNMeta } from "/vercel/path0/src/pages/artists/index.vue?macro=true";
import { default as index7aRKFbPTybMeta } from "/vercel/path0/src/pages/cart/index.vue?macro=true";
import { default as indext2siMuEToKMeta } from "/vercel/path0/src/pages/checkout/index.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as _91slug_93o3VRdqMP00Meta } from "/vercel/path0/src/pages/posts/[slug].vue?macro=true";
import { default as indexwG6ue63SviMeta } from "/vercel/path0/src/pages/posts/index.vue?macro=true";
import { default as privacyKGoRMpsRpVMeta } from "/vercel/path0/src/pages/privacy.vue?macro=true";
import { default as _91slug_93YTgYUv6Ih4Meta } from "/vercel/path0/src/pages/releases/[slug].vue?macro=true";
import { default as indexOafzpqWPgfMeta } from "/vercel/path0/src/pages/releases/index.vue?macro=true";
import { default as _91slug_93X87UilsBldMeta } from "/vercel/path0/src/pages/shop/[slug].vue?macro=true";
import { default as cassettesVGtI49KTueMeta } from "/vercel/path0/src/pages/shop/cassettes.vue?macro=true";
import { default as index7peolLGcLDMeta } from "/vercel/path0/src/pages/shop/index.vue?macro=true";
import { default as merchwCFRhCxJaTMeta } from "/vercel/path0/src/pages/shop/merch.vue?macro=true";
import { default as order_45success9YtXStNsKQMeta } from "/vercel/path0/src/pages/shop/order-success.vue?macro=true";
import { default as slug_45backupnt8sykPEy9Meta } from "/vercel/path0/src/pages/shop/slug-backup.vue?macro=true";
export default [
  {
    name: aboutTska6UMHuWMeta?.name ?? "about",
    path: aboutTska6UMHuWMeta?.path ?? "/about",
    meta: aboutTska6UMHuWMeta || {},
    alias: aboutTska6UMHuWMeta?.alias || [],
    redirect: aboutTska6UMHuWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zny7bE9xqjMeta?.name ?? "artists-slug",
    path: _91slug_93zny7bE9xqjMeta?.path ?? "/artists/:slug()",
    meta: _91slug_93zny7bE9xqjMeta || {},
    alias: _91slug_93zny7bE9xqjMeta?.alias || [],
    redirect: _91slug_93zny7bE9xqjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/artists/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexqcGbOSNNUNMeta?.name ?? "artists",
    path: indexqcGbOSNNUNMeta?.path ?? "/artists",
    meta: indexqcGbOSNNUNMeta || {},
    alias: indexqcGbOSNNUNMeta?.alias || [],
    redirect: indexqcGbOSNNUNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/artists/index.vue").then(m => m.default || m)
  },
  {
    name: index7aRKFbPTybMeta?.name ?? "cart",
    path: index7aRKFbPTybMeta?.path ?? "/cart",
    meta: index7aRKFbPTybMeta || {},
    alias: index7aRKFbPTybMeta?.alias || [],
    redirect: index7aRKFbPTybMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indext2siMuEToKMeta?.name ?? "checkout",
    path: indext2siMuEToKMeta?.path ?? "/checkout",
    meta: indext2siMuEToKMeta || {},
    alias: indext2siMuEToKMeta?.alias || [],
    redirect: indext2siMuEToKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexkP7wmsbr11Meta?.name ?? "index",
    path: indexkP7wmsbr11Meta?.path ?? "/",
    meta: indexkP7wmsbr11Meta || {},
    alias: indexkP7wmsbr11Meta?.alias || [],
    redirect: indexkP7wmsbr11Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93o3VRdqMP00Meta?.name ?? "posts-slug",
    path: _91slug_93o3VRdqMP00Meta?.path ?? "/posts/:slug()",
    meta: _91slug_93o3VRdqMP00Meta || {},
    alias: _91slug_93o3VRdqMP00Meta?.alias || [],
    redirect: _91slug_93o3VRdqMP00Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/posts/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexwG6ue63SviMeta?.name ?? "posts",
    path: indexwG6ue63SviMeta?.path ?? "/posts",
    meta: indexwG6ue63SviMeta || {},
    alias: indexwG6ue63SviMeta?.alias || [],
    redirect: indexwG6ue63SviMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: privacyKGoRMpsRpVMeta?.name ?? "privacy",
    path: privacyKGoRMpsRpVMeta?.path ?? "/privacy",
    meta: privacyKGoRMpsRpVMeta || {},
    alias: privacyKGoRMpsRpVMeta?.alias || [],
    redirect: privacyKGoRMpsRpVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93YTgYUv6Ih4Meta?.name ?? "releases-slug",
    path: _91slug_93YTgYUv6Ih4Meta?.path ?? "/releases/:slug()",
    meta: _91slug_93YTgYUv6Ih4Meta || {},
    alias: _91slug_93YTgYUv6Ih4Meta?.alias || [],
    redirect: _91slug_93YTgYUv6Ih4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/releases/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexOafzpqWPgfMeta?.name ?? "releases",
    path: indexOafzpqWPgfMeta?.path ?? "/releases",
    meta: indexOafzpqWPgfMeta || {},
    alias: indexOafzpqWPgfMeta?.alias || [],
    redirect: indexOafzpqWPgfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/releases/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93X87UilsBldMeta?.name ?? "shop-slug",
    path: _91slug_93X87UilsBldMeta?.path ?? "/shop/:slug()",
    meta: _91slug_93X87UilsBldMeta || {},
    alias: _91slug_93X87UilsBldMeta?.alias || [],
    redirect: _91slug_93X87UilsBldMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/shop/[slug].vue").then(m => m.default || m)
  },
  {
    name: cassettesVGtI49KTueMeta?.name ?? "shop-cassettes",
    path: cassettesVGtI49KTueMeta?.path ?? "/shop/cassettes",
    meta: cassettesVGtI49KTueMeta || {},
    alias: cassettesVGtI49KTueMeta?.alias || [],
    redirect: cassettesVGtI49KTueMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/shop/cassettes.vue").then(m => m.default || m)
  },
  {
    name: index7peolLGcLDMeta?.name ?? "shop",
    path: index7peolLGcLDMeta?.path ?? "/shop",
    meta: index7peolLGcLDMeta || {},
    alias: index7peolLGcLDMeta?.alias || [],
    redirect: index7peolLGcLDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: merchwCFRhCxJaTMeta?.name ?? "shop-merch",
    path: merchwCFRhCxJaTMeta?.path ?? "/shop/merch",
    meta: merchwCFRhCxJaTMeta || {},
    alias: merchwCFRhCxJaTMeta?.alias || [],
    redirect: merchwCFRhCxJaTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/shop/merch.vue").then(m => m.default || m)
  },
  {
    name: order_45success9YtXStNsKQMeta?.name ?? "shop-order-success",
    path: order_45success9YtXStNsKQMeta?.path ?? "/shop/order-success",
    meta: order_45success9YtXStNsKQMeta || {},
    alias: order_45success9YtXStNsKQMeta?.alias || [],
    redirect: order_45success9YtXStNsKQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/shop/order-success.vue").then(m => m.default || m)
  },
  {
    name: slug_45backupnt8sykPEy9Meta?.name ?? "shop-slug-backup",
    path: slug_45backupnt8sykPEy9Meta?.path ?? "/shop/slug-backup",
    meta: slug_45backupnt8sykPEy9Meta || {},
    alias: slug_45backupnt8sykPEy9Meta?.alias || [],
    redirect: slug_45backupnt8sykPEy9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/shop/slug-backup.vue").then(m => m.default || m)
  }
]